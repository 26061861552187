<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.661" x2="0.287" y1="0.143" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="Phone" transform="translate(-210 -288)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(210 288)" width="60"/>
    <path d="M24.571,21.856c-2,2-2,4-4,4s-4-2-6-4-4-4-4-6,2-2,4-4-4-8-6-8-6,6-6,6c0,4,4.109,12.109,8,16s12,8,16,8c0,0,6-4,6-6s-6-8-8-6Z" data-name="Icon metro-phone" id="Icon_metro-phone" style="fill: url(#linear-gradient);" transform="translate(222.429 299.144)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>